<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button v-b-modal.decrement-modal variant="danger">کم کردن</b-button>
    <b-button v-b-modal.increment-modal variant="success" class="mr-1"
      >اضافه کردن</b-button
    >
    <b-card title="انبار" class="mt-3">
      <!-- <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button> -->
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">نام محصول</label>
                  <b-form-input
                    id="title"
                    v-model="searchParams.variety"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-md-6">
                <b-form-group label="نوع تغییرات" label-for="type">
                  <b-form-select id="type" v-model="searchParams.type">
                    <b-form-select-option value="increment"
                      >افزایش</b-form-select-option
                    >
                    <b-form-select-option value="decrement"
                      >کاهش</b-form-select-option
                    >
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button
              class="btn btn-primary btn-sm float-left"
              type="submit"
              :disabled="disabled"
            >
              جست و جو
            </button>
          </form>
        </b-card>
      </b-collapse>
      <b-table
        responsive
        striped
        hover
        v-if="items.data"
        :fields="table_fields"
        :items="items.data.data"
      >
        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>

        <template v-slot:cell(type)="data">
          <span v-if="data.item.type == 'increment'" class="badge-success"
            >افزایش</span
          >

          <span v-else class="badge-danger">کاهش</span>
        </template>
        <template v-slot:cell(variety)="data">
          <span v-if="data.item.variety">
            {{ data.item.variety.fullTitle }}
          </span>
        </template>
      </b-table>
      <div
        class="alert alert-danger text-right"
        v-if="items.data && items.data.data == ''"
      >
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination
      :limit="2"
      v-if="items.data && !search"
      :data="items.data"
      @pagination-change-page="loadItems"
    >
    </pagination>
    <pagination
      :limit="2"
      v-else-if="items.data && search"
      :data="items.data"
      @pagination-change-page="searchItems"
    >
    </pagination>

    <b-modal id="increment-modal" title="اضافه کردن به انبار" hide-footer>
      <form @submit.prevent="increaseQuantity" id="increment-item">
        <div class="form-group">
          <label for="product"
            >انتخاب محصول
            <i
              class="fas fa-asterisk text-danger"
              style="font-size: 0.5rem;"
            ></i
          ></label>
          <multiselect
            :options="products"
            label="title"
            v-model="newVariety.product"
            id="product"
            @input="loadVarieties"
            track-by="id"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            :disabled="disabled"
          >
          </multiselect>
        </div>
        <div v-if="!newVariety.loading && varieties.length > 0">
          <div class="form-group">
            <label for="variety"
              >انتخاب تنوع
              <i
                class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"
              ></i
            ></label>
            <multiselect
              class="form-group"
              :options="varieties"
              label="fullTitle"
              v-model="newVariety.variety"
              id="variety"
              track-by="id"
              placeholder=""
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
            </multiselect>
            <!-- <input type="hidden" name="variety_id" :value="newVariety.variety ? newVariety.variety.id : '' " :disabled="disabled"> -->
          </div>
        </div>
        <div v-if="sellers != '' && newVariety.variety">
          <label for="seller">
            فروشنده
            <i
              class="fas fa-asterisk text-danger"
              style="font-size: 0.5rem;"
            ></i
          ></label>
          <multiselect
            @input="showPrice()"
            id="seller"
            class="form-group"
            :options="sellers"
            label="name"
            track-by="id"
            v-model="selected_seller"
            placeholder=""
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
          >
          </multiselect>
          <input
            type="hidden"
            name="seller_id"
            :value="selected_seller != null ? selected_seller.id : ''"
          />
        </div>
        <div v-if="newVariety.variety != null">
          <div class="form-group">
            <div class="d-flex justify-content-between mb-1">
              <div>
                موجودی فروشنده : {{ store != null ? store.in_stock : "0" }}
              </div>
              <div>موجودی کل : {{ newVariety.variety.in_storage }}</div>
            </div>
            <label for="in_storage"> تعداد در انبار</label>

            <input
              class="form-control"
              id="number"
              name="number"
              v-model="number"
            />
          </div>
        </div>

        <div v-if="show_price">
          <div class="form-group">
            <label for="increment-price"> قیمت</label>
            <input
              class="form-control"
              id="price"
              title="price"
              v-model="price"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="description"
            >توضیحات
            <i
              class="fas fa-asterisk text-danger"
              style="font-size: 0.5rem;"
            ></i
          ></label>
          <b-form-textarea
            id="description"
            name="description"
            :disabled="disabled"
          ></b-form-textarea>
        </div>
        <div class="clearfix">
          <button
            class="btn btn-success float-right"
            type="submit"
            :disabled="disabled"
          >
            ثبت
          </button>
          <b-button
            class="float-left"
            variant="white"
            type="button"
            @click="$root.$emit('bv::hide::modal', 'increment')"
            >بستن</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal id="decrement-modal" title="کم کردن از انبار" hide-footer>
      <form @submit.prevent="decreaseQuantity" id="decrease-item">
        <div class="form-group">
          <label for="product"
            >انتخاب محصول
            <i
              class="fas fa-asterisk text-danger"
              style="font-size: 0.5rem;"
            ></i
          ></label>
          <multiselect
            :options="products"
            label="title"
            v-model="newVariety.product"
            id="product"
            @input="loadVarieties"
            track-by="id"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            :disabled="disabled"
          >
          </multiselect>
        </div>
        <div v-if="!newVariety.loading && varieties.length > 0">
          <div class="form-group">
            <label for="variety"
              >انتخاب تنوع
              <i
                class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"
              ></i
            ></label>
            <multiselect
              class="form-group"
              :options="varieties"
              label="fullTitle"
              v-model="newVariety.variety"
              id="variety"
              track-by="id"
              placeholder=""
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :disabled="disabled"
            >
            </multiselect>
            <input
              type="hidden"
              name="variety_id"
              :value="newVariety.variety ? newVariety.variety.id : ''"
              :disabled="disabled"
            />
          </div>
        </div>
        <div v-if="sellers != '' && newVariety.variety">
          <label for="seller">
            فروشنده
            <i
              class="fas fa-asterisk text-danger"
              style="font-size: 0.5rem;"
            ></i
          ></label>
          <multiselect
            @input="showPrice()"
            id="seller"
            class="form-group"
            :options="sellers"
            label="name"
            track-by="id"
            v-model="selected_seller"
            placeholder=""
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
          >
          </multiselect>
          <input
            type="hidden"
            name="seller_id"
            :value="selected_seller != null ? selected_seller.id : ''"
          />
        </div>
        <div v-if="store != null && selected_seller != null">
          <div class="form-group">
            <div class="d-flex justify-content-between mb-1">
              <div>
                موجودی فروشنده : {{ store != null ? store.in_stock : "0" }}
              </div>
              <div>موجودی کل : {{ newVariety.variety.in_storage }}</div>
            </div>
            <label for="in_storage"> تعداد در انبار</label>
            <input
              class="form-control"
              id="number"
              name="number"
              v-model="number"
            />
          </div>
        </div>
        <div v-if="store == null && selected_seller != null">
          <span class="text-danger">
            این تنوع برای این فروشنده ثبت نشده است!
          </span>
        </div>
        <div class="form-group">
          <label for="description"
            >توضیحات
            <i
              class="fas fa-asterisk text-danger"
              style="font-size: 0.5rem;"
            ></i
          ></label>
          <b-form-textarea
            id="description"
            name="description"
            data-required
            :disabled="disabled"
          ></b-form-textarea>
        </div>
        <div class="clearfix">
          <button
            class="btn btn-success float-right"
            type="submit"
            :disabled="disabled"
          >
            ثبت
          </button>
          <b-button
            class="float-left"
            variant="white"
            type="button"
            @click="$root.$emit('bv::hide::modal', 'decrement')"
            >بستن</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import mixins from "./mixins/mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      url: "/api/admin/log/store",
      title: "انبار",
      table_fields: [
        {
          key: "id",
          label: "شناسه ",
        },
        {
          key: "variety",
          label: "نام محصول",
        },
        {
          key: "description",
          label: "توضیح",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "type",
          label: "نوع تغییرات",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
      ],
      newVariety: {
        product: {},
        variety: null,
        loading: false,
      },
      products: [],
      varieties: [],
      number: 0,
      selected_seller: null,
      sellers: [],
      show_price: false,
      store: null,
    };
  },
  methods: {
    loadSeller(id) {
      this.selected_seller = null;
      this.$axios
        .get(
          this.$root.baseUrl + `/api/admin/sellers?all=true&category_id=${id}`
        )
        .then((response) => {
          this.sellers = response.data.data;
          this.sellers.map(
            (item) =>
              (item.name =
                item.name +
                " " +
                item.last_name +
                " - نام شرکت : " +
                item.company_name)
          );
        });
    },
    loadVarieties(product) {
      console.log(product);
      this.newVariety.loading = true;
      this.varieties = [];
      this.newVariety.variety = null;
      this.$axios
        .get(this.$root.baseUrl + "/api/admin/list/varieties/" + product.id)
        .then((response) => {
          this.newVariety.loading = false;
          if (response.data.data.varieties.length > 0) {
            this.varieties = response.data.data.varieties;
            this.loadSeller(product.category_id ?? product.category.id);
          } else {
            window.swal("برای محصول انتخابی تنوعی وجود ندارد");
          }
        });
    },
    decreaseQuantity() {
      this.disabled = true;
      let form = document.getElementById("decrease-item");
      let formData = new FormData(form);
      this.$axios
        .post(
          this.$root.baseUrl +
            "/api/admin/decrement/store/" +
            this.newVariety.variety.id,
          formData
        )
        .then(() => {
          this.items.data.data.unshift({
            id: "",
            variety: this.newVariety.variety,
            description:
              "به تعداد " + this.number + " توسط مدیر از انبار کم شد ",
            quantity: this.number,
            type: "decrement",
          });
          this.$root.$emit("bv::hide::modal", "decrement-modal");
          this.newVariety = {
            product: {},
            variety: null,
            loading: false,
          };
          this.selected_seller = null;
          this.sellers = [];
          this.varieties = [];
          this.show_price = false;
        })
        .catch((error) => {
          this.$root.error_notification(error);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    increaseQuantity() {
      this.disabled = true;
      let form = document.getElementById("increment-item");
      let formData = new FormData(form);
      if (this.show_price) {
        const price = document.getElementById("price");

        if (price != null) {
          let org_price =
            this.price <= 999 ? this.price : this.price.replace(/\D/g, "");
          formData.append(`${price.title}`, org_price);
        }
      }
      this.$axios
        .post(
          this.$root.baseUrl +
            "/api/admin/increment/store/" +
            this.newVariety.variety.id,
          formData
        )
        .then(() => {
          this.items.data.data.unshift({
            id: "",
            variety: this.newVariety.variety,
            description:
              "به تعداد " + this.number + " توسط مدیر به انبار اضافه شد ",
            quantity: this.number,
            type: "increment",
          });
          this.$root.$emit("bv::hide::modal", "increment-modal");
          this.newVariety = {
            product: {},
            variety: null,
            loading: false,
          };
          this.selected_seller = null;
          this.sellers = [];
          this.varieties = [];
          this.show_price = false;
        })
        .catch((error) => {
          this.$root.error_notification(error);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    showPrice() {
      this.$axios
        .get(
          this.$root.baseUrl +
            `/api/admin/sellers/${this.selected_seller.id}/varieties/${this.newVariety.variety.id}/store`
        )
        .then((response) => {
          this.store = response.data.data;
          this.store != null
            ? (this.show_price = false)
            : (this.show_price = true);
        });
    },
  },
  created() {
    this.loadItems();
    this.$axios
      .get(this.$root.baseUrl + "/api/admin/list/products")
      .then((response) => {
        this.products = response.data.data;
      });
    this.$axios
      .get(this.$root.baseUrl + "/api/admin/log/store?all=true")
      .then((response) => {
        this.storeAll = response.data.data;
      });

    this.searchParams = {
      variety: "",
      type: "",
    };
    // window.$.get('/api/admin/log/store', response => {
    //   if (response.success) {
    //     this.items = response.data
    //     this.show = false
    //   }
    // })
    // window.decreaseQuantity = response => {
    //   if (response.success) {
    //     this.items.data.unshift(response.data)
    //     this.$root.$emit('bv::hide::modal', 'decrement')
    //     this.newVariety.product = {}
    //     this.newVariety.variety = {}
    //     this.newVariety.loading = false
    //   }
    // }

    // window.increaseQuantity = response => {
    //   if (response.success) {
    //     this.items.data.unshift(response.data)
    //     this.$root.$emit('bv::hide::modal', 'increment')
    //     this.newVariety.product = {}
    //     this.newVariety.variety = {}
    //     this.newVariety.loading = false
    //   }
    // }
  },
};
</script>
