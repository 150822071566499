export default {
    data() {
        return {
            items: [],
            editItemValue:{},
            edit:{},
            show: false,
            search: false,
            disabled: false,
            ckeditorLoaded: false,
            price:0,
            amount:0,
            search_price:0,
            searchUrl:'',
            searchParams: {}
        }
    },
    mounted() {
        document.title = this.title
        window.$('body').removeClass('active')
        if (this.$root.getCookie("adminAuthorization")) {
            this.$axios.defaults.headers.common["Authorization"] = this.$root.getCookie(
                "adminAuthorization"
            );
        }
    },
    watch: {
        price: function(newValue) {
            let englishNumber = this.convertToEnglishNumber(newValue);
            const result = englishNumber
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.price = result;
        },
        search_price: function(newValue) {
            let englishNumber = this.convertToEnglishNumber(newValue);
            const result = englishNumber
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.search_price = result;
        },
        amount: function(newValue) {
            let englishNumber = this.convertToEnglishNumber(newValue);
            const result = englishNumber
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.amount = result;
        },
    },
    methods: {
        numberFormat (num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        convertToEnglishNumber(input) {
            var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
            input = "" + input + "";
            return input.replace(/[\u06F0-\u06F9]/g, function(m) {
            return persianDigits.indexOf(m);
            });
        },
        loadCkeditor(){
            if (!this.ckeditorLoaded) {
                this.ckeditorLoaded = true;
                let script = document.createElement('script');
                script.src = "/editors/ckeditor/ckeditor.js"
                window.$('head').append(script)
            }
            setTimeout(() => {
                window.CKEDITOR.replace('body');
            }, 0);
        },
        loadItems(page = 1) {
            this.show = true
            this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page).then(response => {
                this.items = response.data
                this.show = false
            })
        },
        deleteItem(id) {
            window.swal({
                title: "آیتم حذف شود؟",
                text: "این عملیات غیر قابل بازگشت خواهد بود",
                icon: "warning",

                showCancelButton: true,
                showConfirmButton: true,
                dangerMode: true,
                buttons: ["خیر", "بله حذف کن"]
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.$axios.delete(this.$root.baseUrl + this.url + `/${id}`)
                            .then((response) => {
                                this.items.data.data = this.items.data.data.filter(item => item.id != id)
                                window.swal({
                                    icon: "success",
                                    showConfirmButton: true,
                                    text: response.data.message,
                                    button: "باشه",
                                })
                            })
                            .catch(error => {
                                window.swal({
                                    title: 'خطا',
                                    showConfirmButton: true,
                                    text: error.response.data.message,
                                    button: "باشه",


                                })
                            })
                    }
                })
                .catch(() => {

                })
        },
        editItem(index){
            this.edit = {}
            let data = this.items.data.data ? this.items.data.data : this.items.data;
            let item = window.clone(data[index])
            item.index = index
            this.edit = item
            this.$root.$emit('bv::show::modal', 'edit-modal')
        },
        showItem(item){
            this.edit=item
            this.$root.$emit('bv::show::modal', 'show-modal')
        },
        newItem(){
            this.disabled = true
            let form = document.getElementById('new-item')
            let formData = new FormData(form)
            const price = document.getElementById('price')
            const amount = document.getElementById('amount')
            if(price != null) {
                let org_price= this.price <= 999 ? this.price : this.price.replace(/\D/g, "");
                formData.append(`${price.title}`, org_price);
            }
            if(amount != null) {
                let org_amount= this.amount <= 999 ? this.amount : this.amount.replace(/\D/g, "");
                formData.append(`${amount.title}`, org_amount);
            }

            this.$axios.post(this.$root.baseUrl + this.url , formData)
              .then(response => {
                  this.$root.success_notification(response.data.message)
                  if (this.items.data.data) {
                      this.items.data.data.unshift(response.data.data)
                  } else {
                      this.items.data.unshift(response.data.data)
                  }
                  this.$root.$emit('bv::hide::modal', 'new-modal')
                  this.price = 0
                  this.amount = 0
              })
              .catch(error => {
                this.$root.error_notification(error)
              })
              .finally(() => {
                  this.disabled = false
              })
        },
        itemEdited(){
            this.disabled = true
            let form = document.getElementById('edit-item')
            let formData = new FormData(form)
            const price = document.getElementById('edit-price')
            const amount = document.getElementById('edit-amount')
            if(price != null) {
                let org_price= this.price <= 999 ? this.price : this.price.replace(/\D/g, "");
                formData.append(`${price.title}`, org_price);
            }
            if(amount != null) {
                let org_amount= this.amount <= 999 ? this.amount : this.amount.replace(/\D/g, "");
                formData.append(`${amount.title}`, org_amount);
            }

            this.$axios.post(this.$root.baseUrl + this.url + `/${this.edit.id}` , formData)
              .then(response => {
                  this.$root.success_notification(response.data.message)
                  this.$root.$set(this.items.data.data ? this.items.data.data : this.items.data, this.edit.index, response.data.data)
                  this.$root.$emit('bv::hide::modal', 'edit-modal')
                this.price = 0
                this.amount = 0
              })
              .catch(error => {
                this.$root.error_notification(error)
              })
              .finally(() => {
                  this.disabled = false
              })
        },
        searchItems(page = 1) {
            this.disabled = true
            this.search = true
            // let org_price = this.search_price <= 999 ? this.search_price : this.search_price.replace(/\D/g, "");
            // let org_amount = this.amount <= 999 ? this.amount : this.amount.replace(/\D/g, "");

            this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page , {params: this.searchParams})
              .then(response => {
                this.search = true
                this.items = response.data
              })
              .catch(error => {
                this.$root.error_notification(error)
              })
              .finally(() => {
                this.disabled = false
            })},

    },
    filters: {
        persianDate (date) {
            return window.moment(date).format('HH:mm jYYYY/jMM/jDD ')
        },
        date(date) {
            var now = new Date();
            let start = window.moment(date); // some random moment in time (in ms)
            let end = window.moment(now); // some random moment after start (in ms)
            let diff = end.diff(start);
            let time = window.moment.utc(diff).format("H:m:ss");
            let dayDiff = diff / (1000 * 60 * 60 * 24);
            let hour = time.split(":")[0];
            let min = time.split(":")[1];
            let second = time.split(":")[2];
            if ((hour == 0 && min == 0 && second <= 59 && dayDiff <= 1) || diff < 0) {
              return " چند ثانیه پیش";
            } else if (hour == 0 && min <= 59 && dayDiff <= 1) {
              return min + " دقیقه پیش";
            } else if (hour >= 1 && 24 >= hour && dayDiff <= 1) {
              return "حدود " + hour + " ساعت پیش";
            } else if (dayDiff > 1) {
              return window.moment(date).format("dddd jDD jMMMM jYYYY");
            }
        },
    },
}
